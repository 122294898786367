/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { Autoplay } from 'swiper/modules';
import { gameDefaultImagepng, GameBadge } from '../../../images';
import SkeletonBackground from '../../common/SkeletonBackground';

function HomeGamesComponent({
  id,
  data,
  type,
  gotoGame,
  delay,
}) {
  const userId = Cookies.get('userId');
  const [gameList, setGameList] = useState([]);
  const [mobileGameList, setMobileGameList] = useState([]);
  const windowSize = useSelector((state) => state.setting.windowSize);
  const { t } = useTranslation('common');
  const isMobile = useSelector((state) => state.setting.isMobile);

  const fetchGames = async (id, brandIcons) => {
    // const result = await getGames({ brandId: id, branchId: data?.branchId, type });
    if (id) {
      const filterBrand = brandIcons.find((x) => x._id === id);
      filterBrand?.games?.map((x) => {
        x.brandName = filterBrand.name;
      });
      const filterType = filterBrand?.games?.filter((x) => x.type === type.toString());

      const findBrandIcon = brandIcons.find((x) => x.brandId === filterBrand._id);
      if (findBrandIcon) {
        filterType?.map((x) => x.brandThumbnail = findBrandIcon.imageUrl);
      }

      setGameList(filterType);
    } else {
      const result = [];

      // Replace icon from brand icons
      // Filter visible brand
      data.brandIcons = data?.brandIcons?.filter((x) => x.isVisible);

      data?.brandIcons?.map((x) => {
        if (x[`imageUrl_${type}`]) {
          result.push({
            _id: x.brandId,
            games: x.games, // all games show
            isVisible: x.isVisible,
            name: x.name,
            displayName: x.displayName,
            sorting: x.sorting,
            sortings: x.sortings?.map((x) => (x === 0 ? null : x)),
            thumbnail: x[`imageUrl_${type}_sec`] || x[`imageUrl_${type}`],
          });
        }
      });

      const gamesResult = [];

      result.map((brand) => {
        brand.games.map((x) => {
          if (x.status !== '1') {
            gamesResult.push({
              ...x,
              brandThumbnail: brand.thumbnail,
              brandName: brand.name,
              brandId: brand._id.toString(),
            });
          }
        });
      });

      let allgames = [];
      allgames = gamesResult.filter(
        (x) => x.type === type.toString()
          && x?.thumbnail
          // && x?.gameId
          && x.status !== '1',
      );

      allgames = allgames.sort((a, b) => {
        // Check if both games have the hotGame property
        if (a.hotGame !== undefined && b.hotGame !== undefined) {
          return (
            a.hotGame - b.hotGame || a.name.localeCompare(b.name)
          );
        }

        // If one of the games doesn't have the hotGame property, prioritize the one that has it
        if (a.hotGame !== undefined) {
          return -1;
        }
        if (b.hotGame !== undefined) {
          return 1;
        }

        // If neither game has the hotGame property, just sort by name
        return a.name.localeCompare(b.name);
      });

      allgames = allgames.filter((x) => x.thumbnail);
      allgames = allgames.slice(0, 27);

      setGameList(allgames);

      // mobile
      const pages = [[]];
      let index = 0;
      const length = allgames.length || 0;

      allgames.map((x) => {
        if ((length > 9) ? (pages[index]?.length <= 2) : ((length > 4) ? (pages[index]?.length <= 2) : (pages[index]?.length <= 0))) {
        // if ((length > 9) ? (pages[index]?.length <= 2) : ((length > 4) ? (pages[index]?.length <= 1) : (pages[index]?.length <= 0))) {
          pages[index].push(x);
        } else {
          index += 1;
          pages.push([x]);
        }
      });
      setMobileGameList(pages);
    }
  };

  useEffect(() => {
    fetchGames(id, data?.brandIcons);
  }, [id]);

  // useEffect(() => {
  //   const brandIconMap = data?.brandIcons?.reduce((acc, icon) => {
  //     acc[icon.brandId] = icon.imageUrl;
  //     return acc;
  //   }, {});

  //   const updatedGames = data?.recommendedGames?.map((game) => ({
  //     ...game,
  //     branchImageURL: brandIconMap[game.gameProvider] || null,
  //   }));

  //   const filteredGames = updatedGames?.filter((game) => game.gameCategory === type.toString());

  //   setGameList(filteredGames);

  //   const pages = [[]];
  //   let index = 0;
  //   const length = filteredGames?.length || 0;

  //   filteredGames?.forEach((game) => {
  //     if ((length > 9) ? (pages[index]?.length <= 2) : ((length > 4) ? (pages[index]?.length <= 1) : (pages[index]?.length <= 0))) {
  //       pages[index].push(game);
  //     } else {
  //       index += 1;
  //       pages.push([game]);
  //     }
  //   });
  //   setMobileGameList(pages);
  // }, [type, data]);

  return (
    <>
      {
        isMobile
          ? (
            <Swiper
              spaceBetween={7}
              slidesPerView={3.1}
              autoplay={{
                delay: delay || 2500,
                disableOnInteraction: false,
              }}
              loop
              modules={[Autoplay]}
            >
              {mobileGameList
                // .filter((x, i) => i <= 13)
                .map((page, i) => (
                  <SwiperSlide
                    key={`game-img-b-${i}`}
                    className='cursor-pointer relative'
                  >
                    {
                      page.map((x, i1) => (
                        <div
                          className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap mb-2'
                          key={`${`${i}${i1}`}`}
                          onClick={() => {
                            const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

                            const existingIndex = currentArray.findIndex((item) => item._id === x._id);

                            let updatedArray;

                            if (existingIndex !== -1) {
                              updatedArray = [
                                ...currentArray.slice(0, existingIndex),
                                ...currentArray.slice(existingIndex + 1),
                                x,
                              ];
                            } else {
                              updatedArray = [...currentArray, x];
                            }

                            localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

                            gotoGame(x);
                          }}
                        >
                          <div className='absolute z-[2] leading-unset'>
                            <div style={{ width: 40 }} className='leading-unset'>
                              <Image src={GameBadge} alt='' />
                            </div>
                            <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                              <img src={x.brandThumbnail} alt='' />
                            </div>
                          </div>
                          <div className='relative game-overlay-img'>
                            <div className='game-overlay'>
                              <div className='triangle' />
                              <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                            </div>
                            <SkeletonBackground id={`skeleton-id-${i}`} />
                            <img
                              className='hidden w-full'
                              src={x.thumbnail || gameDefaultImagepng.src}
                              onLoad={(e) => {
                                document.getElementById(`skeleton-id-${i}`)?.remove();
                                e.target.classList?.remove('hidden');
                              }}
                              alt='Cricket betting sites in Brazil'
                            />
                          </div>
                        </div>
                      ))
                    }
                  </SwiperSlide>
                ))}
            </Swiper>
          )
          : (
            <Swiper
              spaceBetween={7}
              slidesPerView={windowSize?.width >= 1200
                ? 5
                : (windowSize?.width >= 768 ? 4.6 : windowSize?.width >= 580 ? 4 : 3)}
              autoplay={{
                delay: delay || 2500,
                disableOnInteraction: false,
              }}
              loop
              modules={[Autoplay]}
            >
              {gameList
                .filter((x, i) => i <= 13)
                .map((x, i) => (
                  <SwiperSlide
                    key={`game-img-b-${i}`}
                    className='cursor-pointer'
                  >
                    <div
                      className='cursor-pointer rounded-lg overflow-hidden game-overlay-wrap'
                      key={`${i}-${i._id}`}
                      onClick={() => {
                        const currentArray = JSON.parse(localStorage.getItem(`recentlyPlayedGames${userId}`)) || [];

                        const existingIndex = currentArray.findIndex((item) => item._id === x._id);

                        let updatedArray;

                        if (existingIndex !== -1) {
                          updatedArray = [
                            ...currentArray.slice(0, existingIndex),
                            ...currentArray.slice(existingIndex + 1),
                            x,
                          ];
                        } else {
                          updatedArray = [...currentArray, x];
                        }

                        localStorage.setItem(`recentlyPlayedGames${userId}`, JSON.stringify(updatedArray));

                        gotoGame(x);
                      }}
                    >
                      <div className='absolute z-[2] leading-unset'>
                        <div style={{ width: 40 }} className='leading-unset'>
                          <Image src={GameBadge} alt='' />
                        </div>
                        <div className='leading-unset absolute top-0 left-2' style={{ width: 18 }}>
                          <img src={x.brandThumbnail} alt='' />
                        </div>
                      </div>
                      <div className='relative game-overlay-img'>
                        <div className='game-overlay'>
                          <div className='triangle' />
                          <p className='font-semibold text-base mt-1'>{t('home.play')}</p>
                        </div>
                        <SkeletonBackground id={`skeleton-id-${i}`} />
                        <img
                          className='hidden w-full'
                          src={x.thumbnail || gameDefaultImagepng.src}
                          onLoad={(e) => {
                            document.getElementById(`skeleton-id-${i}`)?.remove();
                            e.target.classList?.remove('hidden');
                          }}
                          alt='Cricket betting sites in Brazil'
                        />
                      </div>
                      {/* <div className='bg-customgray flex items-center w-full h-7 md:h-8 flex justify-center game-overlay-text px-2 -mt-1'>
                  <div className='leading-none mr-0.5' style={{ width: 22 }}>
                    <img src={x.brandThumbnail} alt='' />
                  </div>
                  <p
                    className='leading-none text-xs font-semibold opacity-80 capitalize cut-text-game'
                  >
                    {x.brandName || x.name}
                  </p>
                </div> */}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          )
      }
    </>
  );
}

export default HomeGamesComponent;
